import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Constipation";

// Download link - http://www.fbrennangastro.com.au/downloads/gesaconstipation.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[
				title,
				"Myths about constipation",
				"Constipation leading to diseases",
				"How frequently to go to the toilet",
				"How much time to spend on the toilet",
				"Medications causing constipation",
				"constipation treatment",
			]}
			type="article"
			description="Everything you need to know about constipation, how frequently you should go to the toilet, what classifies as constipation, good toilet practices, constipation treatment"
		/>
		<H1>{title}</H1>
		<Par>
			Constipation is a common disorder in the general population. The incidence increases with age, particularly in
			females, with up to 20% of adults reporting this problem at some stage of their lives. Constipation is a symptom
			and not a disease and in most people the problem is temporary and diet related. With this condition the stool is
			usually small, hard and dry and difficult to evacuate. This group includes those who go to the toilet very
			infrequently and those who have to strain excessively to pass a stool. In many patients infrequent bowel actions
			are associated with bloating, abdominal discomfort or pain and in this situation, the symptom may be part of an
			IBS type syndrome.
		</Par>
		<Par>
			Ideally patients should spend a maximum of 3-4 minutes in the toilet to complete a satisfactory evacuation of
			their bowel and anything that facilitates this is far better than chronic straining. There is a wide variation in
			the normal bowel habit, it is not necessary to go every day but a reasonable target would be to go 3 times a week.
			Spending long periods of time in the toilet is not recommended. On this note please do not bring reading material
			into the toilet and this includes newspapers, iphones and ipads!
		</Par>
		<Par>
			Constipation is seen in association with a range of diseases and may also be associated with a large number of
			both prescribed and over the counter medications. The following lists are a guide and not meant to be
			comprehensive. Any chronic illness that reduces mobility is also likely to be associated with constipation.
		</Par>
		<h2>Diseases and conditions associated with constipation</h2>
		<ul>
			<li className="mt-3">Diabetes mellitus</li>
			<li className="mt-3">Hypothyroidism</li>
			<li className="mt-3">Multiple sclerosis</li>
			<li className="mt-3">Anorexia nervosa</li>
			<li className="mt-3">Parkinsons</li>
			<li className="mt-3">Pregnancy</li>
			<li className="mt-3">Stroke</li>
			<li className="mt-3">Hypercalcaemia</li>
		</ul>
		<h2>Drugs associated with constipation</h2>
		<ul>
			<li className="mt-3">Antidepressants</li>
			<li className="mt-3">Iron supplements</li>
			<li className="mt-3">Antipsychotics</li>
			<li className="mt-3">Aluminium (antacids)</li>
			<li className="mt-3">Antihistamines</li>
			<li className="mt-3">Opiate painkillers </li>
			<li className="mt-3">Antispasmodics</li>
			<li className="mt-3">Calcium channel blockers</li>
		</ul>
		<Par>
			The majority of patients with constipation do not fit into these categories and have what is sometimes referred to
			as either simple constipation or constipation predominant irritable bowel syndrome (IBS). Occasionally there is an
			outlet problem (pevic floor dyssynergia) this is a complex problem which is best discussed with a specialist in
			this area.
		</Par>
		<Par>
			Before discussing management I feel it best to deal initially with the common myths associated with constipation
		</Par>
		<h2>Myth No 1 "You probably need to drink more water"</h2>
		<Par>
			While it is important in the hot Australian climate that we maintain adequate hydration, particularly if you are
			elderly, there is no evidence that by drinking water alone you will make your bowels move. Water, when taken in
			conjunction with increased fibre or fibre supplements, may help but not when taken alone.
		</Par>
		<h2>Myth No 2 "There must be something wrong with your diet"</h2>
		<Par>
			Generally as a community we need to consume more fibre (fruit and vegetables) and less refined sugars and
			saturated fats but for many people with constipation, increasing fibre just aggravates problems with bloating and
			wind without helping their constipation. Having said this the first step in managing constipation should be to
			increase the fibre intake to 30gm/day (the average western diet frequently just contains 15-18gms fibre/day).
			Fruits, vegetables, cereals, nuts and some breads are high in fibre. Useful fibre supplements include Metamucil,
			Benefibre, Fybogel and Agiofibre. A slow increase in the amount of fibre consumed may help avoid the excessive
			bloating and gas seen in some patients. If bloating and gas production are unacceptable then other forms of
			laxatives will be required and this brings me to the third myth associated with this subject.
		</Par>
		<h2>Myth No 3 "Don't take laxatives as they will weaken your bowel and you will become dependent on them"</h2>
		<Par>
			This anecdote comes from an era when certain laxatives were overused as a weight loss aid by patients for many
			years even when they did not have constipation. Please note that laxatives do not help in any way with weight
			loss. Excessive straining at stool over many years is more likely to cause harm than long term use of any
			laxative. This is particularly the case in females where the pelvic floor may already have been weakened by
			childbirth.
		</Par>
		<h2>Laxative categories</h2>
		<Par>
			<b>Osmotic agents:</b> These agents help to retain fluid within the intestinal lumen and thereby increase stool
			frequency. These agents do not cause the bowel to contract and should be safe in the long term unless there are
			significant cardiac or renal problems. This category includes Movicol sachets, Lactulose and sorbitol and Epsom
			salts (magnesium sulphate). Movicol sachets can be taken 1-2 times a day but in severe cases up to 8 a day can be
			taken in the short term. Lactulose and sorbitol are synthetic carbohydrates that are not metabolised by intestinal
			enzymes thus water and electrolytes remain in the lumen due to the osmotic effect of undigested sugar.
			Occasionally these may cause some flatulence and bloating. Epsom salts (magnesium sulphate) is classed as a saline
			laxative and is generally very safe unless you have renal failure. Some patients find Epsom salts intolerable
			because of the taste but there are ways around this problem.
		</Par>
		<Par>
			<b>Stimulant laxatives:</b> This includes bisacodyl (Dulcolax) and senna (Senokot). These alter electrolyte
			transport throughout the intestinal mucosa but also increase intestinal motor activity. These agents are generally
			very effective but may cause cramping pain in some and chronic use may be associated with hypokalaemia (low
			potassium) and some other problems. There is no convincing evidence that these agents cause structural or
			functional impairment of the colon or increase the risk of colorectal cancer.
		</Par>
		<h2>When should I consider using a suppository?</h2>
		<Par>
			Suppositries such as glycerine or bisacodyl may be useful in certain patients but their long term use should
			ideally be discussed with a doctor.
		</Par>
		<h2>Who should have a colonoscopy?</h2>
		<Par>
			If you are over the age of 40 and the constipation is recent in onset and persists for longer than 3 weeks or if
			there has been any bleeding then a colonoscopy is indicated. If there are other symptoms such as loss of appetite,
			weight loss, nausea or vomiting then you should consult a doctor. If you are under 40 and there has not been any
			bleeding or any other alarm symptoms then you should discuss your individual circumstances with your GP as there
			are alternatives to a colonoscopy that you may wish to consider.
		</Par>
	</Blog>
);
